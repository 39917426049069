import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Alert,
  Switch,
  FormControlLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import { format, addDays, isAfter } from 'date-fns';

const VacationManager = () => {
  const [vacationEnabled, setVacationEnabled] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [vacationPeriods, setVacationPeriods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, periodId: null });

  // Load vacation data from localStorage
  useEffect(() => {
    const savedVacationData = localStorage.getItem('vacationData');
    if (savedVacationData) {
      const data = JSON.parse(savedVacationData);
      setVacationEnabled(data.enabled || false);
      setVacationPeriods(data.periods || []);
    }
  }, []);

  // Save vacation data to localStorage
  const saveVacationData = (enabled, periods) => {
    console.log('Saving vacation data:', { enabled, periods });
    
    // Save in VacationManager format
    localStorage.setItem('vacationData', JSON.stringify({
      enabled,
      periods
    }));

    // Save in Appointments-compatible format
    const latestPeriod = periods.length > 0 ? periods[periods.length - 1] : null;
    const availabilitySettings = {
      vacation: {
        enabled: enabled && periods.length > 0,
        startDate: latestPeriod?.startDate,
        endDate: latestPeriod?.endDate,
        availableDates: [], // No available dates during vacation
        availableTimeSlots: {} // No available time slots during vacation
      }
    };
    
    console.log('Saving availability settings:', availabilitySettings);
    localStorage.setItem('availabilitySettings', JSON.stringify(availabilitySettings));
  };

  const handleVacationToggle = (event) => {
    const enabled = event.target.checked;
    setVacationEnabled(enabled);
    saveVacationData(enabled, vacationPeriods);
    
    if (!enabled) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleSetVacation = () => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates');
      return;
    }

    if (!isAfter(endDate, startDate)) {
      setError('End date must be after start date');
      return;
    }

    const newPeriod = {
      id: Date.now().toString(),
      startDate: startDate.toISOString(),  // Store as ISO string
      endDate: endDate.toISOString(),      // Store as ISO string
      createdAt: new Date().toISOString()  // Store as ISO string
    };

    console.log('Setting new vacation period:', newPeriod);

    const updatedPeriods = [...vacationPeriods, newPeriod];
    setVacationPeriods(updatedPeriods);
    saveVacationData(true, updatedPeriods);
    
    setStartDate(null);
    setEndDate(null);
    setSuccess('Vacation period set successfully');
    setVacationEnabled(true);
  };

  const handleDeleteClick = (periodId) => {
    setDeleteDialog({ open: true, periodId });
  };

  const handleDeleteClose = () => {
    setDeleteDialog({ open: false, periodId: null });
  };

  const handleDeleteConfirm = () => {
    const updatedPeriods = vacationPeriods.filter(period => period.id !== deleteDialog.periodId);
    setVacationPeriods(updatedPeriods);
    saveVacationData(updatedPeriods.length > 0, updatedPeriods);
    
    if (updatedPeriods.length === 0) {
      setVacationEnabled(false);
    }
    
    setSuccess('Vacation period deleted successfully');
    handleDeleteClose();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Vacation Manager
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess('')}>
          {success}
        </Alert>
      )}

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ mb: 4 }}>
          <FormControlLabel
            control={
              <Switch
                checked={vacationEnabled}
                onChange={handleVacationToggle}
                disabled={isLoading}
              />
            }
            label="Enable Vacation Mode"
          />
        </Box>

        {vacationEnabled && (
          <>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Vacation Start Date"
                    value={startDate}
                    onChange={setStartDate}
                    minDate={addDays(new Date(), 1)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Vacation End Date"
                    value={endDate}
                    onChange={setEndDate}
                    minDate={startDate ? addDays(new Date(startDate), 1) : addDays(new Date(), 2)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              onClick={handleSetVacation}
              disabled={!startDate || !endDate}
              sx={{ mb: 3 }}
            >
              Set Vacation Period
            </Button>

            <Typography variant="h6" gutterBottom>
              Current Vacation Periods
            </Typography>
            <List>
              {vacationPeriods.map((period) => (
                <ListItem
                  key={period.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteClick(period.id)}
                      disabled={isLoading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={`${format(new Date(period.startDate), 'MMM d, yyyy')} - ${format(new Date(period.endDate), 'MMM d, yyyy')}`}
                  />
                </ListItem>
              ))}
              {vacationPeriods.length === 0 && (
                <ListItem>
                  <ListItemText
                    primary="No vacation periods set"
                    primaryTypographyProps={{ color: 'text.secondary' }}
                  />
                </ListItem>
              )}
            </List>
          </>
        )}
      </Paper>

      <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteClose}
      >
        <DialogTitle>Delete Vacation Period</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this vacation period? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VacationManager;
