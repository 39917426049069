import React, { useState, useEffect } from 'react';
import { Alert, useTheme, useMediaQuery } from '@mui/material';
import { getBarberStatus } from '../api/barberStatus';
import { useLocation } from 'react-router-dom';

const BarberStatusNotification = () => {
  const [isBusy, setIsBusy] = useState(getBarberStatus());
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const checkStatus = () => {
      setIsBusy(getBarberStatus());
    };

    // Check every 3 seconds
    const interval = setInterval(checkStatus, 3000);
    
    // Listen for storage changes from other tabs
    window.addEventListener('storage', checkStatus);

    return () => {
      clearInterval(interval);
      window.removeEventListener('storage', checkStatus);
    };
  }, []);

  // Don't render anything on admin pages or when not busy
  if (location.pathname.includes('/admin') || !isBusy) {
    return null;
  }

  return (
    <Alert 
      severity="info" 
      sx={{ 
        position: 'fixed',
        ...(isMobile ? {
          top: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          maxWidth: '200px',
          fontSize: '0.8rem',
          padding: '0px 8px',
          '& .MuiAlert-icon': {
            fontSize: '20px',
            padding: '4px 0'
          }
        } : {
          top: 30,
          left: '38%',
        }),
        zIndex: 1000,
        width: 'auto',
        maxWidth: '90%',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
          transform: isMobile ? 'translateX(-50%) scale(1.05)' : 'scale(1.3)',
          boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
          backgroundColor: '#e3f2fd',
          color: '#ff0000',
          '& .MuiAlert-icon': {
            color: '#ff0000'
          }
        }
      }}
    >
      The barber is currently with a client. Please feel free to book your appointment for later.
    </Alert>
  );
};

export default BarberStatusNotification;
