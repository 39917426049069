// In-memory storage for barber status
// Create a custom event for status updates
const STATUS_CHANGE_EVENT = 'barberStatusChange';
const STORAGE_KEY = 'barberBusyStatus';

export const getBarberStatus = () => {
    const status = localStorage.getItem(STORAGE_KEY);
    return status === 'true';
};

export const setBarberStatus = (status) => {
    localStorage.setItem(STORAGE_KEY, status);
    // Dispatch custom event to notify all components
    window.dispatchEvent(new CustomEvent(STATUS_CHANGE_EVENT, { 
        detail: { isBusy: status }
    }));
    return status;
};
