import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import classic_cut from '../images/classic-cut.jpg'
import beard_trim from '../images/beard-trim.jpg'
import Hot_Towel_Shave from '../images/hot-towel-shave.jpg'
import Hair_Beard_Combo from '../images/Hair_Beard_Combo.jpg'
import BarberStatusNotification from '../components/BarberStatusNotification';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledCardMedia = styled(CardMedia)({
  height: 240,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const services = [
  {
    title: 'Classic Haircut',
    price: '$30',
    description: 'Traditional haircut with modern styling and more .....',
    image: classic_cut,
    duration: '45 min'
  },
  {
    title: 'Beard Trim',
    price: '$20',
    description: 'Professional beard grooming and shaping .and more .....',
    image: beard_trim,
    duration: '30 min'
  },
  {
    title: 'Hot Towel Shave',
    price: '$35',
    description: 'Luxurious straight razor shave with hot towel treatment',
    image: Hot_Towel_Shave,
    duration: '45 min'
  },
  {
    title: 'Hair & Beard Combo',
    price: '$45',
    description: 'Complete grooming package with haircut and beard trim',
    image: Hair_Beard_Combo,
    duration: '1 hour'
  }
];

const Services = () => {
  return (
    <>
      <BarberStatusNotification />
      <Container sx={{ py: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ 
          color: 'var(--primary-color)',
          textAlign: 'center',
          mb: 4
        }}>
          Our Services
        </Typography>
        <Typography sx={{ 
          color: 'var(--primary-color)', 
          fontSize: '1.1rem', 
          mb: 6,
          textAlign: 'center'
        }}>
          Professional Grooming Services for the Modern Gentleman
        </Typography>
        
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                <StyledCardMedia
                  image={service.image}
                  title={service.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                    {service.title}
                  </Typography>
                  <Typography variant="h6" color="primary" gutterBottom>
                    {service.price}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {service.description}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Duration: {service.duration}
                    </Typography>
                    <Button
                      component={Link}
                      to="/appointments"
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: 'secondary.main',
                        '&:hover': {
                          bgcolor: 'secondary.dark',
                        }
                      }}
                    >
                      Book Now
                    </Button>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Services;
