import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress, Alert } from '@mui/material';

  /**
   * AppointmentAction is a React component that handles appointment actions
   * such as confirm or cancel. It verifies a token passed in the URL and
   * updates the appointment status accordingly. It also displays a message
   * to the user based on the status of the action.
   *
   * @param {string} id - The ID of the appointment.
   * @param {string} action - The action to take on the appointment, either
   * 'confirm' or 'cancel'.
   * @param {string} token - A token to verify the action.
   */
const AppointmentAction = () => {
  const { id, action } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [status, setStatus] = useState('processing');
  const [error, setError] = useState(null);

  const verifyToken = (token, bookingId, action) => {
    if (!token) return false;
    
    const parts = token.split('-');
    if (parts.length !== 4) return false;
    
    const [tokenBookingId, tokenAction, timestamp] = parts;
    
    // Verify booking ID and action match
    if (tokenBookingId !== bookingId || tokenAction !== action) return false;
    
    // Check if token is not expired (24 hours)
    const tokenTime = parseInt(timestamp, 10);
    const now = Date.now();
    const tokenAge = now - tokenTime;
    const maxAge = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    
    return tokenAge < maxAge;
  };

  const handleAppointment = async () => {
    try {
      if (!verifyToken(token, id, action)) {
        throw new Error('Invalid or expired token');
      }

      // Here you would make an API call to your backend to update the appointment status
      // For now, we'll simulate the API call
      await new Promise(resolve => setTimeout(resolve, 1500));

      if (action === 'confirm') {
        // Update appointment status to confirmed in your database
        setStatus('success');
      } else if (action === 'cancel') {
        // Update appointment status to cancelled in your database
        setStatus('cancelled');
      }
    } catch (error) {
      setError(error.message);
      setStatus('error');
    }
  };

  useEffect(() => {
    handleAppointment();
  }, [id, action, token]);

  const getStatusMessage = () => {
    switch (status) {
      case 'processing':
        return {
          title: 'Processing...',
          message: 'Please wait while we process your request.'
        };
      case 'success':
        return {
          title: 'Appointment Confirmed!',
          message: 'The appointment has been successfully confirmed.'
        };
      case 'cancelled':
        return {
          title: 'Appointment Cancelled',
          message: 'The appointment has been cancelled.'
        };
      case 'error':
        return {
          title: 'Error',
          message: error || 'An error occurred while processing your request.'
        };
      default:
        return {
          title: 'Unknown Status',
          message: 'An unknown error occurred.'
        };
    }
  };

  const statusMessage = getStatusMessage();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {status === 'processing' ? (
          <CircularProgress sx={{ mb: 3 }} />
        ) : (
          <Alert
            severity={status === 'success' ? 'success' : status === 'cancelled' ? 'info' : 'error'}
            sx={{ width: '100%', mb: 3 }}
          >
            {statusMessage.title}
          </Alert>
        )}
        
        <Typography variant="body1" sx={{ mb: 3 }}>
          {statusMessage.message}
        </Typography>

        {status !== 'processing' && (
          <Typography
            variant="body2"
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
              '&:hover': { textDecoration: 'underline' }
            }}
            onClick={() => navigate('/admin/appointments')}
          >
            Return to Appointments
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default AppointmentAction;
