import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  Box,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  AdapterDateFns,
} from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import BarberStatusNotification from '../components/BarberStatusNotification';

// Replace with your Stripe publishable key
const stripePromise = loadStripe('your_publishable_key');

const services = [
  { name: 'Classic Haircut', price: 30, duration: '45 min' },
  { name: 'Beard Trim', price: 20, duration: '30 min' },
  { name: 'Hot Towel Shave', price: 35, duration: '45 min' },
  { name: 'Hair & Beard Combo', price: 45, duration: '1 hour' },
];

const timeSlots = [
  '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
  '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM',
  '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM'
];

const steps = ['Select Service', 'Choose Date & Time', 'Your Information', 'Confirmation'];

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const PaymentForm = ({ amount, onSuccess, onError, processing, setProcessing }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);

    try {
      // Here you would typically create a payment intent on your server
      // For demo purposes, we'll simulate a successful payment
      await new Promise(resolve => setTimeout(resolve, 1000));
      onSuccess();
    } catch (error) {
      onError(error.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Payment Details
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Amount to pay: ${amount}
        </Typography>
        <Box sx={{ mt: 2, mb: 3 }}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={!stripe || processing}
        >
          {processing ? 'Processing...' : 'Pay Now'}
        </Button>
      </Box>
    </form>
  );
};

const Appointments = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedService, setSelectedService] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [paymentOption, setPaymentOption] = useState('later');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [isBooked, setIsBooked] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [vacationPeriod, setVacationPeriod] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [showVacationMessage, setShowVacationMessage] = useState(true);

  useEffect(() => {
    const savedSettings = localStorage.getItem('availabilitySettings');
    if (savedSettings) {
      const settings = JSON.parse(savedSettings);
      if (settings.vacation?.enabled && 
          settings.vacation?.startDate && 
          settings.vacation?.endDate) {
        setVacationPeriod({
          start: new Date(settings.vacation.startDate),
          end: new Date(settings.vacation.endDate),
          availableDates: settings.vacation.availableDates || [],
          availableTimeSlots: settings.vacation.availableTimeSlots || {}
        });
      } else {
        setVacationPeriod(null);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedDate && vacationPeriod) {
      const dateStr = format(selectedDate, 'yyyy-MM-dd');
      const availableTimes = vacationPeriod.availableTimeSlots[dateStr] || [];
      setAvailableSlots(availableTimes);
    } else if (!vacationPeriod) {
      setAvailableSlots(timeSlots);
    }
  }, [selectedDate, vacationPeriod]);

  const shouldDisableDate = (date) => {
    // Check if there's any vacation period that includes this date
    const vacationData = localStorage.getItem('vacationData');
    if (vacationData) {
      const { enabled, periods } = JSON.parse(vacationData);
      console.log('Checking date:', date);
      console.log('Vacation data:', { enabled, periods });
      
      if (enabled && periods.length > 0) {
        const dateToCheck = new Date(date);
        const isVacation = periods.some(period => {
          const startDate = new Date(period.startDate);
          const endDate = new Date(period.endDate);
          console.log('Comparing dates:', {
            dateToCheck: dateToCheck.toISOString(),
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
          });
          return dateToCheck >= startDate && dateToCheck <= endDate;
        });
        console.log('Is vacation date:', isVacation);
        return isVacation;
      }
    }
    return false;
  };

  const getAvailableTimeSlots = () => {
    if (!selectedDate) return [];
    
    // Check if selected date is during vacation
    const vacationData = localStorage.getItem('vacationData');
    if (vacationData) {
      const { enabled, periods } = JSON.parse(vacationData);
      console.log('Checking time slots for date:', selectedDate);
      console.log('Vacation data:', { enabled, periods });
      
      if (enabled && periods.length > 0) {
        const dateToCheck = new Date(selectedDate);
        const isVacation = periods.some(period => {
          const startDate = new Date(period.startDate);
          const endDate = new Date(period.endDate);
          console.log('Comparing dates for slots:', {
            dateToCheck: dateToCheck.toISOString(),
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
          });
          return dateToCheck >= startDate && dateToCheck <= endDate;
        });
        console.log('Is vacation date (for slots):', isVacation);
        if (isVacation) {
          console.log('No slots available - vacation period');
          return [];
        }
      }
    }
    
    return timeSlots;
  };

  const handleContinueBooking = () => {
    setShowVacationMessage(false);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1 && paymentOption === 'later') {
      handleBookingSuccess();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    setPaymentError('');
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePaymentOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setPaymentOption(newOption);
    }
  };

  const handlePaymentSuccess = () => {
    handleBookingSuccess();
  };

  const handleBookingSuccess = () => {
    // Save the booking
    const newBooking = {
      id: Date.now(),
      service: selectedService,
      date: format(selectedDate, 'yyyy-MM-dd'),
      time: selectedTime,
      customer: formData,
      paymentOption,
      status: 'new',
      createdAt: new Date().toISOString()
    };

    // Get existing bookings or initialize empty array
    const existingBookings = JSON.parse(localStorage.getItem('bookings') || '[]');
    
    // Add new booking
    localStorage.setItem('bookings', JSON.stringify([newBooking, ...existingBookings]));

    // Update notifications
    const notifications = JSON.parse(localStorage.getItem('adminNotifications') || '[]');
    const newNotification = {
      id: Date.now(),
      type: 'new_booking',
      booking: newBooking,
      read: false,
      createdAt: new Date().toISOString()
    };
    localStorage.setItem('adminNotifications', JSON.stringify([newNotification, ...notifications]));

    setIsBooked(true);
    // Reset form
    setSelectedService('');
    setSelectedDate(null);
    setSelectedTime('');
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
    setActiveStep(0);
    setPaymentError('');
    setPaymentOption('later');
  };

  const handlePaymentError = (error) => {
    setPaymentError(error);
  };

  const getServicePrice = () => {
    const service = services.find(s => s.name === selectedService);
    return service ? service.price : 0;
  };

  const canProceed = () => {
    switch (activeStep) {
      case 0:
        return selectedService !== '';
      case 1:
        return selectedDate && selectedTime && !shouldDisableDate(selectedDate);
      case 2:
        return Object.values(formData).every((value) => value.trim() !== '');
      case 3:
        return true;
      default:
        return false;
    }
  };

  const getStepContent = (step) => {
    if (isBooked) {
      return (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography variant="h5" color="primary" gutterBottom>
            Appointment Booked Successfully!
          </Typography>
          <Typography variant="body1" paragraph>
            Thank you for choosing Nourdine's Elegant Cuts. Your appointment has been confirmed.
          </Typography>
          {paymentOption === 'later' && (
            <Typography variant="body1" color="text.secondary" paragraph>
              Please remember to bring ${getServicePrice()} for payment at the time of service.
            </Typography>
          )}
          <Typography variant="body1" paragraph>
            We'll send you a confirmation email with your appointment details.
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsBooked(false)}
            sx={{ mt: 2 }}
          >
            Book Another Appointment
          </Button>
        </Box>
      );
    }

    switch (step) {
      case 0:
        return (
          <Box>
            <Grid container spacing={3}>
              {services.map((service) => (
                <Grid item xs={12} sm={6} key={service.name}>
                  <Paper
                    sx={{
                      p: 2,
                      cursor: 'pointer',
                      bgcolor: selectedService === service.name ? 'primary.light' : 'background.paper',
                      '&:hover': { bgcolor: 'primary.light' },
                    }}
                    onClick={() => setSelectedService(service.name)}
                  >
                    <Typography variant="h6">{service.name}</Typography>
                    <Typography variant="subtitle1">Price: ${service.price}</Typography>
                    <Typography variant="body2">Duration: {service.duration}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {selectedService && (
              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom>
                  Payment Preference
                </Typography>
                <ToggleButtonGroup
                  value={paymentOption}
                  exclusive
                  onChange={handlePaymentOptionChange}
                  color="primary"
                >
                  <ToggleButton value="now">
                    Pay Now Online
                  </ToggleButton>
                  <ToggleButton value="later">
                    Pay Later In-Store
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
          </Box>
        );

      case 1:
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Select Date"
                  value={selectedDate}
                  onChange={setSelectedDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  minDate={new Date()}
                  shouldDisableDate={shouldDisableDate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Select Time"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  disabled={!selectedDate}
                >
                  {getAvailableTimeSlots().map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {vacationPeriod && selectedDate && getAvailableTimeSlots().length === 0 && (
                <Grid item xs={12}>
                  <Typography color="error">
                    No time slots available for this date. Please select another date.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </LocalizationProvider>
        );

      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
              />
            </Grid>
          </Grid>
        );

      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Appointment Summary
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Service: {selectedService}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Date: {selectedDate ? format(selectedDate, 'MMMM d, yyyy') : ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Time: {selectedTime}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Name: {formData.firstName} {formData.lastName}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Email: {formData.email}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Phone: {formData.phone}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Payment Method: {paymentOption === 'now' ? 'Online Payment' : 'In-Store Payment'}
                </Typography>
              </Grid>
            </Grid>
            {paymentOption === 'now' && (
              <>
                <Divider sx={{ my: 3 }} />
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    amount={getServicePrice()}
                    onSuccess={handlePaymentSuccess}
                    onError={handlePaymentError}
                    processing={processing}
                    setProcessing={setProcessing}
                  />
                </Elements>
                {paymentError && (
                  <Typography color="error" sx={{ mt: 2 }}>
                    {paymentError}
                  </Typography>
                )}
              </>
            )}
          </Box>
        );

      default:
        return 'Unknown step';
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <BarberStatusNotification />
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Book an Appointment
        </Typography>
        
        {vacationPeriod && showVacationMessage ? (
          <Paper sx={{ p: 3, bgcolor: '#fff3e0', mt: 2 }}>
            <Typography variant="h6" color="primary" gutterBottom>
              Limited Availability Notice
            </Typography>
            <Typography paragraph>
              The barber is on vacation from{' '}
              {format(new Date(vacationPeriod.start), 'MMMM d, yyyy')} to{' '}
              {format(new Date(vacationPeriod.end), 'MMMM d, yyyy')}.
            </Typography>
            <Typography paragraph>
              However, there are some limited time slots available during this period.
              You can view and book these special slots by clicking continue.
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                onClick={() => window.history.back()}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                onClick={handleContinueBooking}
              >
                View Available Slots
              </Button>
            </Box>
          </Paper>
        ) : (
          <>
            {vacationPeriod && (
              <Paper sx={{ p: 2, mb: 3, bgcolor: '#e3f2fd' }}>
                <Typography color="primary">
                  You are booking during a vacation period. Only selected time slots are available.
                </Typography>
              </Paper>
            )}
            {!isBooked && (
              <Stepper activeStep={activeStep} sx={{ py: 3 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <Box sx={{ mt: 2 }}>
              {getStepContent(activeStep)}
              {!isBooked && (activeStep !== 3 || paymentOption === 'later') && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={!canProceed()}
                  >
                    {activeStep === steps.length - 1 && paymentOption === 'later' ? 'Confirm Booking' : 'Next'}
                  </Button>
                </Box>
              )}
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default Appointments;
