import React from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Paper,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import BarberStatusNotification from '../components/BarberStatusNotification';

const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  return (
    <>
      <BarberStatusNotification />
      <Container sx={{ py: 8 }}>
        <Grid container spacing={6}>
          {/* Get in Touch Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom sx={{ color: 'var(--primary-color)' }}>
              Get in Touch
            </Typography>
            <Typography sx={{ color: 'var(--primary-color)', fontSize: '1.1rem', mb: 3 }}>
              Ready for a fresh look? Have questions about our services? We're here to help! Drop us a message, and our expert barbers will get back to you promptly. Whether you need styling advice or want to learn more about our premium grooming services, we're just a message away.
            </Typography>
            
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="First Name"
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Last Name"
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Message"
                    name="message"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          
          {/* Contact Information Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ 
              p: 4, 
              height: '100%',
              background: 'rgba(255, 255, 255, 0.95)',
              backdropFilter: 'blur(10px)',
              borderRadius: '12px',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.2)',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 40px rgba(0, 0, 0, 0.15)',
              }
            }}>
              <Typography variant="h5" gutterBottom sx={{ 
                color: 'var(--secondary-color)',
                fontWeight: 600,
                textTransform: 'uppercase',
                letterSpacing: '1px',
                borderBottom: '2px solid var(--secondary-color)',
                paddingBottom: '10px',
                marginBottom: '30px'
              }}>
                Contact Information
              </Typography>
              <Box sx={{ mt: 4 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 4,
                  '&:hover': {
                    transform: 'translateX(10px)',
                    transition: 'transform 0.3s ease'
                  }
                }}>
                  <LocationOnIcon sx={{ 
                    mr: 2, 
                    color: 'var(--secondary-color)',
                    fontSize: '2rem'
                  }} />
                  <Typography sx={{ 
                    color: 'var(--primary-color)', 
                    fontSize: '1.1rem',
                    '&:hover': {
                      color: 'var(--secondary-color)',
                      transition: 'color 0.3s ease'
                    }
                  }}>
                    50 Revere St, Revere, MA 02151
                  </Typography>
                </Box>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 4,
                  '&:hover': {
                    transform: 'translateX(10px)',
                    transition: 'transform 0.3s ease'
                  }
                }}>
                  <PhoneIcon sx={{ 
                    mr: 2, 
                    color: 'var(--secondary-color)',
                    fontSize: '2rem'
                  }} />
                  <Typography sx={{ 
                    color: 'var(--primary-color)', 
                    fontSize: '1.1rem',
                    '&:hover': {
                      color: 'var(--secondary-color)',
                      transition: 'color 0.3s ease'
                    }
                  }}>
                    (555) 123-4567
                  </Typography>
                </Box>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 4,
                  '&:hover': {
                    transform: 'translateX(10px)',
                    transition: 'transform 0.3s ease'
                  }
                }}>
                  <EmailIcon sx={{ 
                    mr: 2, 
                    color: 'var(--secondary-color)',
                    fontSize: '2rem'
                  }} />
                  <Typography sx={{ 
                    color: 'var(--primary-color)', 
                    fontSize: '1.1rem',
                    '&:hover': {
                      color: 'var(--secondary-color)',
                      transition: 'color 0.3s ease'
                    }
                  }}>
                    info@elegantcuts.com
                  </Typography>
                </Box>
              </Box>
              
              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Business Hours
              </Typography>
              <Typography>
                Monday - Friday: 9:00 AM - 8:00 PM<br />
                Saturday: 9:00 AM - 6:00 PM<br />
                Sunday: 10:00 AM - 4:00 PM
              </Typography>
            </Paper>
          </Grid>

          {/* Map Section */}
          <Grid item xs={12}>
            <Paper sx={{ 
              p: 4, 
              background: 'rgba(255, 255, 255, 0.95)',
              backdropFilter: 'blur(10px)',
              borderRadius: '12px',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.2)',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 12px 40px rgba(0, 0, 0, 0.15)',
              }
            }}>
              <Typography variant="h5" gutterBottom sx={{ 
                color: 'var(--secondary-color)',
                fontWeight: 600,
                textTransform: 'uppercase',
                letterSpacing: '1px',
                borderBottom: '2px solid var(--secondary-color)',
                paddingBottom: '10px',
                marginBottom: '30px'
              }}>
                Find Us Here
              </Typography>
              <Box sx={{ 
                width: '100%',
                height: '400px',
                overflow: 'hidden',
                borderRadius: '8px',
                '& iframe': {
                  width: '100%',
                  height: '100%',
                  border: 'none'
                }
              }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2944.8093738274267!2d-70.99242862346611!3d42.4079715711907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e371f5fa4a9891%3A0x6b9c0fc4c1ef8e19!2s50%20Revere%20St%2C%20Revere%2C%20MA%2002151!5e0!3m2!1sen!2sus!4v1705201524244!5m2!1sen!2sus"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Contact;
