import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Switch,
  FormControlLabel,
  Button,
  Chip,
  TextField,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import {
  AdapterDateFns
} from '@mui/x-date-pickers/AdapterDateFns';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, parseISO } from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
//import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { sendConfirmationEmail, sendCancellationEmail } from '../utils/emailService';
import VacationManager from '../components/VacationManager';
import { getBarberStatus, setBarberStatus } from '../api/barberStatus';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const timeSlots = [
  '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
  '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM',
  '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM'
];

const Admin = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });
  const [cancelDialog, setCancelDialog] = useState({ open: false, bookingId: null });
  const [cancelReason, setCancelReason] = useState('');
  const [isBusy, setIsBusy] = useState(getBarberStatus());

  useEffect(() => {
    // Load notifications
    const savedNotifications = localStorage.getItem('adminNotifications');
    if (savedNotifications) {
      setNotifications(JSON.parse(savedNotifications));
    }

    // Load bookings
    const savedBookings = localStorage.getItem('bookings');
    console.log('Loaded bookings:', savedBookings); // Debug log
    if (savedBookings) {
      const parsedBookings = JSON.parse(savedBookings);
      console.log('Parsed bookings:', parsedBookings); // Debug log
      setBookings(parsedBookings);
    }

    // Listen for storage changes from other tabs
    const handleStorageChange = () => {
      setIsBusy(getBarberStatus());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      markNotificationsAsRead();
    }
  };

  const markNotificationsAsRead = () => {
    const updatedNotifications = notifications.map(notification => ({
      ...notification,
      read: true
    }));
    setNotifications(updatedNotifications);
    localStorage.setItem('adminNotifications', JSON.stringify(updatedNotifications));
  };

  const getUnreadCount = () => {
    return notifications.filter(n => !n.read).length;
  };

  const handleBookingAction = async (bookingId, action, reason = '') => {
    setIsProcessing(true);
    setStatusMessage({ type: '', message: '' });

    const booking = bookings.find(b => b.id === bookingId);

    try {
      // First try to send the email
      if (action === 'confirmed') {
        await sendConfirmationEmail(booking);
      } else if (action === 'cancelled') {
        await sendCancellationEmail({ ...booking, cancelReason: reason });
      }

      // If email is sent successfully, update the booking status
      const updatedBookings = bookings.map(booking => {
        if (booking.id === bookingId) {
          return { 
            ...booking, 
            status: action,
            cancelReason: reason,
            cancelledAt: action === 'cancelled' ? new Date().toISOString() : null
          };
        }
        return booking;
      });

      setBookings(updatedBookings);
      localStorage.setItem('bookings', JSON.stringify(updatedBookings));

      setStatusMessage({
        type: 'success',
        message: `Booking ${action}. Email notification sent successfully.`
      });
    } catch (error) {
      console.error('Error processing booking action:', error);
      setStatusMessage({
        type: 'error',
        message: `Error sending email notification. Please try again or contact support.`
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancelClick = (bookingId) => {
    console.log('Cancel clicked for booking:', bookingId); // Debug log
    setCancelDialog({ open: true, bookingId });
    setCancelReason('');
  };

  const handleCancelClose = () => {
    setCancelDialog({ open: false, bookingId: null });
    setCancelReason('');
  };

  const handleCancelConfirm = async () => {
    if (cancelDialog.bookingId && cancelReason.trim()) {
      await handleBookingAction(cancelDialog.bookingId, 'cancelled', cancelReason);
      handleCancelClose();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('isAdminAuthenticated');
    localStorage.removeItem('adminAuthTime');
    navigate('/login');
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.checked;
    setBarberStatus(newStatus);
    setIsBusy(newStatus);
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', flex: 1 }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Notifications & Bookings" />
              <Tab label="Vacation Manager" />
            </Tabs>
          </Box>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleLogout}
            sx={{ ml: 2 }}
          >
            Logout
          </Button>
        </Box>

        <Paper sx={{ mt: 3, p: 3, mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" component="div">
              Barber Status
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={isBusy}
                  onChange={handleStatusChange}
                  color="primary"
                />
              }
              label={isBusy ? "Currently with a client" : "Available for clients"}
            />
          </Box>
        </Paper>

        <TabPanel value={tabValue} index={0}>
          <Typography variant="h4" gutterBottom>
            Notifications & Bookings
          </Typography>

          {statusMessage.message && (
            <Alert 
              severity={statusMessage.type} 
              sx={{ mb: 2 }}
              onClose={() => setStatusMessage({ type: '', message: '' })}
            >
              {statusMessage.message}
            </Alert>
          )}

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Recent Bookings
            </Typography>
            <List>
              {bookings.map((booking) => (
                <React.Fragment key={booking.id}>
                  <ListItem
                    secondaryAction={
                      booking.status === 'new' && (
                        <Box>
                          <IconButton 
                            onClick={() => handleBookingAction(booking.id, 'confirmed')}
                            color="primary"
                            disabled={isProcessing}
                            aria-label="Confirm booking"
                          >
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton 
                            onClick={() => handleCancelClick(booking.id)}
                            color="error"
                            disabled={isProcessing}
                            aria-label="Cancel booking"
                          >
                            <CancelIcon />
                          </IconButton>
                        </Box>
                      )
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography>
                          {booking.customer.firstName} {booking.customer.lastName} - {booking.service}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2">
                            Date: {format(parseISO(booking.date), 'MMM d, yyyy')} at {booking.time}
                          </Typography>
                          <Typography variant="body2">
                            Status: <Chip 
                              size="small" 
                              label={booking.status}
                              color={
                                booking.status === 'confirmed' ? 'success' : 
                                booking.status === 'cancelled' ? 'error' : 
                                'default'
                              }
                            />
                            {booking.cancelReason && (
                              <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
                                Reason: {booking.cancelReason}
                              </Typography>
                            )}
                          </Typography>
                          <Typography variant="body2">
                            Contact: {booking.customer.phone} | {booking.customer.email}
                          </Typography>
                          <Typography variant="body2">
                            Payment: {booking.paymentOption === 'now' ? 'Paid Online' : 'Pay In-Store'}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Notifications
            </Typography>
            <List>
              {notifications.map((notification) => (
                <ListItem key={notification.id}>
                  <ListItemText
                    primary={
                      <Typography>
                        New Booking: {notification.booking.service}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2">
                        {format(parseISO(notification.createdAt), 'MMM d, yyyy h:mm a')} - 
                        {notification.booking.customer.firstName} {notification.booking.customer.lastName}
                      </Typography>
                    }
                  />
                  {!notification.read && (
                    <Chip size="small" label="New" color="primary" />
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <VacationManager />
        </TabPanel>

        <Dialog 
          open={cancelDialog.open} 
          onClose={handleCancelClose}
          aria-labelledby="cancel-dialog-title"
          aria-describedby="cancel-dialog-description"
        >
          <DialogTitle id="cancel-dialog-title">Cancel Appointment</DialogTitle>
          <DialogContent>
            <DialogContentText id="cancel-dialog-description">
              Please provide a reason for cancelling this appointment. This will be included in the notification email to the customer.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="cancel-reason"
              label="Cancellation Reason"
              fullWidth
              multiline
              rows={3}
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelClose} color="primary">
              Back
            </Button>
            <Button 
              onClick={handleCancelConfirm} 
              color="error" 
              variant="contained"
              disabled={!cancelReason.trim()}
            >
              Cancel Appointment
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default Admin;
