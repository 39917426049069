import React from 'react';
import { Navigate } from 'react-router-dom';
import { differenceInHours } from 'date-fns';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('isAdminAuthenticated') === 'true';
  const authTime = localStorage.getItem('adminAuthTime');

  // Check if authentication is expired (24 hours)
  if (isAuthenticated && authTime) {
    const hoursSinceAuth = differenceInHours(new Date(), new Date(authTime));
    if (hoursSinceAuth >= 24) {
      localStorage.removeItem('isAdminAuthenticated');
      localStorage.removeItem('adminAuthTime');
      return <Navigate to="/login" />;
    }
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
