import React from 'react';
import { Container, Typography, Button, Box, Grid, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import BarberStatusNotification from '../components/BarberStatusNotification';

const Home = () => {
  return (
    <Box>
      <BarberStatusNotification />
      <Box
        sx={{
          position: 'relative',
          backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/hero-bg.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '70vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
          overflow: 'hidden',
        }}
      >
        {/* Lamp lighting effects */}
        <div className="lamp-container">
          <div className="lamp lamp-left"></div>
          <div className="lamp lamp-center"></div>
          <div className="lamp lamp-right"></div>
        </div>

        <Container>
          <Typography variant="h2" component="h1" gutterBottom>
            Welcome to Elegant Cuts
          </Typography>
          <Typography variant="h5" gutterBottom>
            Premium Barbershop Experience
          </Typography>
          <Button
            component={Link}
            to="/appointments"
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mt: 4 }}
          >
            Book Appointment
          </Button>
        </Container>
      </Box>

      <Container sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" gutterBottom sx={{ color: 'var(--primary-color)' }}>
              Expert Barbers
            </Typography>
            <Typography sx={{ color: 'var(--primary-color)', fontSize: '1.1rem' }}>
              Our skilled barbers are trained in the latest techniques and styles.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" gutterBottom sx={{ color: 'var(--primary-color)' }}>
              Premium Service
            </Typography>
            <Typography sx={{ color: 'var(--primary-color)', fontSize: '1.1rem' }}>
              Experience luxury grooming with our premium services and products.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" gutterBottom sx={{ color: 'var(--primary-color)' }}>
              Modern Facility
            </Typography>
            <Typography sx={{ color: 'var(--primary-color)', fontSize: '1.1rem' }}>
              Enjoy our contemporary space designed for your comfort.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
