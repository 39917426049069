import emailjs from '@emailjs/browser';

// Initialize EmailJS with your user ID
emailjs.init("MkntKrWCFfAJCy17x");

const SERVICE_ID = 'service_3jcue4s';
const PUBLIC_KEY = 'MkntKrWCFfAJCy17x';
const SHOP_NAME = 'Barber Shop';
const SHOP_EMAIL = 'certagourram@gmail.com';

export const sendConfirmationEmail = async (booking) => {
  const templateParams = {
    to_email: booking.customer.email,
    to_name: `${booking.customer.firstName} ${booking.customer.lastName}`,
    from_name: SHOP_NAME,
    reply_to: SHOP_EMAIL,
    service: booking.service,
    appointment_date: booking.date,
    appointment_time: booking.time,
    payment_method: booking.paymentOption === 'now' ? 'Paid Online' : 'Pay In-Store'
  };

  try {
    console.log('Sending confirmation email with params:', templateParams);
    const response = await emailjs.send(
      SERVICE_ID,
      'template_9f65u9f',
      templateParams,
      PUBLIC_KEY
    );
    console.log('Confirmation email sent successfully:', response);
    return true;
  } catch (error) {
    console.error('Error sending confirmation email:', error);
    throw error;
  }
};

export const sendCancellationEmail = async (booking) => {
  const templateParams = {
    to_email: booking.customer.email,
    to_name: `${booking.customer.firstName} ${booking.customer.lastName}`,
    from_name: SHOP_NAME,
    reply_to: SHOP_EMAIL,
    service: booking.service,
    appointment_date: booking.date,
    appointment_time: booking.time,
    cancel_reason: booking.cancelReason || 'No reason provided'
  };

  try {
    console.log('Sending cancellation email with params:', templateParams);
    const response = await emailjs.send(
      SERVICE_ID,
      'template_a2jc20l',
      templateParams,
      PUBLIC_KEY
    );
    console.log('Cancellation email sent successfully:', response);
    return true;
  } catch (error) {
    console.error('Error sending cancellation email:', error);
    throw error;
  }
};
